@tailwind base;
@tailwind components;
@tailwind utilities;

/* App.css */

/* Change the style of the scrollbar track */
::-webkit-scrollbar {
    width: 8px;
    background-color: #f1f1f1;
  }
  
  /* Change the style of the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
  }
  
  /* Change the style of the scrollbar thumb when hovering */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  